
import { defineComponent, reactive, toRefs } from 'vue'

import { url } from '@/service/config'

//import HelloWorld from '@/components/HelloWorld.vue' // @ is an alias to /src

export default defineComponent({
    components: {},
    props: ['data', 'index'],
    setup() {
        let t = reactive<any>({ url })

        return { ...toRefs(t) }
    }
})
